<template>
  <div class="hero is-soft is-small">
    <div class="hero-body">
      <div class="container">
        <div v-for="item in messages" :key="item.key">
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            mode="out-in"
          >
            <LoadingMessage v-bind="{ message, item }" :key="item.key" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoadingHero',
  data: () => ({ interval: null, index: 0 }),
  components: {
    LoadingMessage: () => import('./LoadingMessage')
  },
  computed: {
    ...mapGetters('explorer', ['form']),
    messages() {
      return [
        {
          key: 'info',
          text: 'Searches can take up to 45 seconds',
          icon: 'fa-info-circle'
        },
        { key: 'make', text: 'Filtering listings by make', icon: 'fa-cars' },
        {
          key: 'model',
          text: 'Comparing listing models your request',
          icon: 'fa-car'
        },
        {
          key: 'year',
          text: 'Applying year filter',
          icon: 'fa-sort-numeric-up-alt'
        },
        {
          key: 'mileage',
          text: 'Analysing listing mileages',
          icon: ' fa-tachometer-fastest'
        },
        {
          key: 'alt1',
          text: 'Compiling filtered results',
          icon: 'fa-boxes'
        },
        {
          key: 'alt2',
          text: 'Sorting listings into groups',
          icon: 'fa-sitemap'
        },
        { key: 'alt3', text: 'Calculating group counts', icon: ' fa-tally' },
        {
          key: 'final',
          text: 'Search taking a while? Try narrowing the criteria',
          icon: ' fa-search-plus'
        }
      ]
    },
    filteredMessages() {
      const hasYear = this.form.maxYear || this.form.minYear
      const hasMileage = this.form.maxMileage || this.form.minMileage

      const messages = this.messages.filter(message => {
        if (message.key === 'year') return hasYear
        if (message.key === 'mileage') return hasMileage
        return true
      })

      return messages
    },
    message() {
      return this.filteredMessages[this.index]
    }
  },
  mounted() {
    const delay = 38000 / this.filteredMessages.length
    this.interval = setInterval(() => this.index++, delay)
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
